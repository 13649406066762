import React from 'react';
import { Heading, Text, List, ListItem } from '@chakra-ui/core';

export default () => (
  <>
    <Heading as="h2" size="md" mb={4}>
      Samtycke
    </Heading>
    <Text>
      När du skriver under dessa krav och fyller i rutorna, godkänner du
      följande:
    </Text>
    <List spacing={2} styleType="disc" stylePos="outside" pl={8} py={4}>
      <ListItem>Att du ställer dig bakom syftet av namninsamlingen.</ListItem>
      <ListItem>
        Att ditt förnamn och första bokstaven i ditt efternamn publiceras på
        skrivunder.fridaysforfuture.se om du godkänt detta.
      </ListItem>
      <ListItem>
        Att din underskrift kan komma att bli en del av en officiell statistik
        för hur många som stödjer syftet av namninsamlingen, som sedan kan
        användas för att påverka politiker och makthavare.
      </ListItem>
    </List>

    <Heading as="h2" size="md" my={4}>
      Behandling av personuppgifter
    </Heading>
    <Text>
      FridaysForFuture är en folkrörelse bestående av strejkande skolungdomar,
      där FridaysForFuture Sverige är juridiskt ansvarig för behandlingen av
      dina personuppgifter. Dina uppgifter kommer behandlas på ett sätt som
      överensstämmer med gällande dataskyddslagstiftning. Vår avsikter är att
      tillgodose en korrekt, laglig och transparent personuppgiftsbehandling. De
      kommer inte användas till något som du inte gått med på och lagras tills
      kampanjen är avslutad (max 2 år) sedan kommer behandlingen av
      personuppgifterna att upphöra.
    </Text>

    <Heading as="h2" size="md" my={4}>
      Rättigheter
    </Heading>
    <Text>Dina rättigheter är följande:</Text>
    <List spacing={2} styleType="disc" stylePos="outside" pl={8} py={4}>
      <ListItem>
        Du har rätt att få dina personuppgifter rättade ifall du upptäcker att
        de är felaktiga eller inaktuella.
      </ListItem>
      <ListItem>
        Du har rätt att begära att få dina personuppgifter raderade från
        namninsamlingen.
      </ListItem>
      <ListItem>
        Du har rätt att få inblick i vad dina personuppgifter används till.
      </ListItem>
      <ListItem>
        Du har rätt att inge klagomål till Datainspektionen om du anser att
        behandlingen av dina personuppgifter sker på ett sätt som strider mot
        det som står i denna Personuppgiftspolicy eller enligt gällande
        dataskyddslagstiftning.
      </ListItem>
    </List>

    <Heading as="h2" size="md" my={4}>
      Säkerhet och sekretess
    </Heading>
    <Text>
      FridaysForFuture intygar att det finns tillräcklig och nödvändig teknisk
      och organisatorisk kapacitet och förmåga, inbegripet tekniska lösningar,
      kompetens, ekonomiska och personella resurser, rutiner och metoder för att
      fullgöra våra skyldigheter enligt denna Personuppgiftspolicy och gällande
      dataskyddslagstiftning.
    </Text>

    <Heading as="h2" size="md" my={4}>
      Ändringar av personuppgiftspolicy
    </Heading>
    <Text>
      FridaysForFuture förbehåller sig rätten att ändra denna
      Personuppgiftspolicy från tid till annan. Om dessa ändringar av
      FridaysForFuture inte anses ha några större konsekvenser för dig kan dessa
      ändringar ske för vilka skäl som helst, utan förvarning. Du hittar alltid
      den gällande Personuppgiftspolicy på skrivunder.fridaysforfuture.se.
    </Text>
  </>
);
