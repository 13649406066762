import React from 'react';
import { ThemeProvider, theme } from '@chakra-ui/core';
import { Global, css } from '@emotion/core';
import { Button } from '@chakra-ui/core';
import Privacy from '../components/Privacy';

const PrivacyPolicy = () => (
  <ThemeProvider theme={theme}>
    <Global
      styles={css`
        body {
          font-family: 'sofia-pro', sans-serif;
        }
      `}
    />
    <main>
      <h1>Personuppgiftspolicy</h1>

      <Privacy />
      <Button as="a" href="/" variantColor="red">
        Gå tillbaka
      </Button>
    </main>
  </ThemeProvider>
);
export default PrivacyPolicy;
